import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SsoUtilService } from '../services/sso-util/sso-util.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainGuardGuard implements CanActivate {
  storageKey: string = environment.storageKey;

  constructor(private sso: SsoUtilService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.sso.user.pipe(
      take(1),
      map(user => {
        if (user) {
          if (user.rol) {
            return true;
          }
        }
        return this.router.createUrlTree(['/login']);
      })
    );
  }

}
