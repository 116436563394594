import { Component, OnInit, OnDestroy } from '@angular/core';
import { SsoUtilService } from 'src/app/services/sso-util/sso-util.service';

@Component({
  selector: 'app-session-expiry-modal',
  templateUrl: './session-expiry-modal.component.html',
  styleUrls: ['./session-expiry-modal.component.css']
})
export class SessionExpiryModalComponent implements OnInit, OnDestroy {
  secondsBeforeExpiry: number = 50;
  countDown: any;

  constructor(private sso: SsoUtilService) { }

  ngOnInit() {
    this.countDown = setInterval(() => {
      this.secondsBeforeExpiry--;
      if (this.secondsBeforeExpiry === 0) {
        clearInterval(this.countDown);
        this.sso.logout();
      }
    }, 1000);
  }

  ngOnDestroy(): void {

  }
}
