import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';
import { MainComponent } from './features/main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMaterialModule } from './ng-material/ng-material.module';
import { SessionExpiryModalComponent } from './features/modals/session-expiry-modal/session-expiry-modal.component';
import { AppListComponent } from './features/main/app-list/app-list.component';
import { MenuComponent } from './features/main/menu/menu.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AlertModalComponent } from './features/modals/alert-modal/alert-modal.component';
import { ImagePreloadDirective } from './directive/image-preload.directive';

import { AcctComponent } from './features/acct/acct.component';
import { ForbiddenComponent } from './features/acct/forbidden/forbidden.component';
import { ActivateAccountComponent } from './features/acct/activate-account/activate-account.component';
import { ResetPasswordComponent } from './features/acct/reset-password/reset-password.component';
import { ChangePasswordComponent } from './features/acct/shared-components/change-password/change-password.component';
import { SetSecretComponent } from './features/acct/shared-components/set-secret/set-secret.component';
import { DisplayDoneComponent } from './features/acct/shared-components/display-done/display-done.component';
import { GetUsernameComponent } from './features/acct/shared-components/get-username/get-username.component';
import { InitializerModule } from './initializer.module';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    SessionExpiryModalComponent,
    AppListComponent,
    MenuComponent,
    AlertModalComponent,
    ImagePreloadDirective,
    AcctComponent,
    ForbiddenComponent,
    ActivateAccountComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    SetSecretComponent,
    DisplayDoneComponent,
    GetUsernameComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InitializerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgMaterialModule, 
    FlexLayoutModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents: [
    AlertModalComponent
  ]
})
export class AppModule { }
