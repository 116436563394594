import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './features/login/login.component';
import { MainComponent } from './features/main/main.component';
import { MainGuardGuard } from './guards/main-guard.guard';
import { LoginGuardGuard } from './guards/login-guard.guard';
import { ActivateAccountComponent } from './features/acct/activate-account/activate-account.component';
import { ResetPasswordComponent } from './features/acct/reset-password/reset-password.component';
import { ForbiddenComponent } from './features/acct/forbidden/forbidden.component';

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardGuard]},
  { path: 'main', component: MainComponent, canActivate: [MainGuardGuard] },
  { path: 'acct/activate', component: ActivateAccountComponent },
  { path: 'acct/reset', component: ResetPasswordComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '**', redirectTo: '/main' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
