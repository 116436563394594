import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEventType
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export class HttpInterceptorService implements HttpInterceptor {
    storageKey: string = environment.storageKey;
    authHeaderName: string = "Authorization";

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const storedToken = localStorage.getItem(this.storageKey);

        if (storedToken) {
            const modifiedRequest = req.clone({
                headers: req.headers.append(this.authHeaderName, storedToken)
            });

            return next.handle(modifiedRequest).pipe(
                tap(event => {
                    if (event.type === HttpEventType.Response) {
                        const incomingToken = event.headers.get(this.authHeaderName);
                        if (incomingToken) {
                            localStorage.setItem(this.storageKey, incomingToken);
                        }
                    }
                })
            );
        }

        return next.handle(req).pipe(
            tap(event => {
                if (event.type === HttpEventType.Response) {
                    const incomingToken = event.headers.get(this.authHeaderName);
                    if (incomingToken) {
                        localStorage.setItem(this.storageKey, incomingToken);
                    }
                }
            })
        );
    }
}
