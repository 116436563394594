import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SsoUtilService } from '../../services/sso-util/sso-util.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupService } from 'src/app/services/dialog/popup.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorMsg: string;
  loginForm: FormGroup;
  isSubmitted = false;
  isLoading = true;
  isValid: boolean = true;
  tokenSub: String = "";

  constructor(private sso: SsoUtilService, private router: Router,
    public popupService: PopupService,
    public santizer: DomSanitizer) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      'username': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required])
    });

    this.sso.user.subscribe(value => {
      this.isLoading = false;
      if (value) {
        this.isValid = true;
        this.tokenSub = value.sub;
      } else {
        this.isValid = false;
      }
    });
  }

  getUserIconStyle(){
    return this.santizer.bypassSecurityTrustStyle('url("assets/images/user.png") no-repeat scroll 7px 7px/18px 18px');
  }

  getPasswordIconStyle(){
    return this.santizer.bypassSecurityTrustStyle('url("assets/images/password.png") no-repeat scroll 7px 7px/18px 18px');
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isLoading = true;
    this.errorMsg = '';

    const authenticateSub: Subscription = this.sso.authenticate(this.loginForm.value).subscribe(
      response => {
        const action = response.body.action;
        const msg = response.body.msg;

        if (action == 'REDIRECT') {
            this.router.navigate(['/acct/activate']);
        }

        if (action === 'PROCEED') {
          authenticateSub.unsubscribe();
          const authorizeSub: Subscription = this.sso.authorize().subscribe(
            response => {
              authorizeSub.unsubscribe();
              this.sso.checkToken();
              
              if (this.tokenSub === "new") {
                this.router.navigate(['/acct/activate']);
              }

              this.router.navigate(['/main']);
            }
          );
        }

        if (action === 'SHOW_ERROR') {
          let data = {
            "message": msg,
            "btnText": 'Close'
          };
          this.popupService.showPopup(data);
          this.isLoading = false;
        }
      },
      error => {
        if (error.status === 0) {
          this.errorMsg = 'Unable to connect to server'
        }
      
        this.isLoading = false;
      });
  }

  resetPassword() {
    this.router.navigate(['/acct/reset']);
  }
}
