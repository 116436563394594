import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from 'src/app/features/modals/alert-modal/alert-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(private dialog: MatDialog) { }

  showPopup(dataObj) {
    return this.dialog.open(AlertModalComponent, {
      width: '390px',
      disableClose: true,
      data: dataObj
    });
  }
}
