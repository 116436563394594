import { Component, OnInit } from '@angular/core';
import { SsoUtilService } from './services/sso-util/sso-util.service';
import { ConfigService } from './services/config/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'app-shell';

  constructor(private sso: SsoUtilService, private config: ConfigService) { }

  ngOnInit(): void {
    this.sso.checkToken();
    this.sso.addReloadOnTokenDeletion();
    
    console.log(this.config.getData());
  }
}
