import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './acct.component.html',
  styleUrls: ['./acct.component.css']
})
export class AcctComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
