import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent {
  anotherData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private dialogRef: MatDialogRef<AlertModalComponent>) {
      this.message = data.message;
      this.btnText = data.btnText;
      this.anotherData = this.data;
  }

  message:string = "";
  btnText:string = "";

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
