import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  title = environment.title;
  appName = environment.appName;

  @ViewChild('sidenav') drawer: MatDrawer;

  constructor() { }


  ngOnInit() {
  }

  toggle() {
    this.drawer.toggle();
  }

}
