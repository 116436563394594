import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-display-done',
  templateUrl: './display-done.component.html',
  styleUrls: ['./display-done.component.css']
})
export class DisplayDoneComponent implements OnInit {

  @Input("message")
  message:string = "";

  constructor() { }

  ngOnInit() {
  }

}
