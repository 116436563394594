import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { SsoUtilService } from '../services/sso-util/sso-util.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {
  constructor(private sso: SsoUtilService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.sso.user.pipe(
      take(1),
      map(token => {
        if (token) {
          if (token.rol) {
            return this.router.createUrlTree(['/main']);
          }
        }
        return true;
      })
    );
  }
}
