import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { SsoUser } from 'src/app/models/sso-user.model';
import { SSOApp } from 'src/app/models/sso-app.model';
import { SsoAdminApp } from 'src/app/models/sso-admin-app.model';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SsoUtilService {
  storageKey: string = environment.storageKey;
  ssoEndpoint: string = environment.ssoEndpoint;
  ssoAdminEndPoint: string = environment.ssoAdminEndPoint;
  ssoAuthenticationSvc: string = this.ssoEndpoint + '/users/access/simple';
  ssoAuthorizationSvc: string = this.ssoEndpoint + '/roles/current';
  ssoGetRoleAppsSvc: string = this.ssoEndpoint + '/details/roles/current/apps';
  ssoAccountDetailsSvc: string = this.ssoEndpoint + '/details/users/current';
  ssoAppIconSvc: string = this.ssoEndpoint + '/details/apps/icons';
  user = new BehaviorSubject<SsoUser>(null);
  expiredEvent = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  authenticate(loginReqBody: { username: string, password: string }): Observable<any> {
    return this.http.post(this.ssoAuthenticationSvc, loginReqBody, { observe: 'response' });
  }

  authorize(): Observable<any> {
    return this.http.get(this.ssoAuthorizationSvc);
  }

  getRoleDescription(): Observable<SsoAdminApp[]>{
    return this.http.get<any>(this.ssoAdminEndPoint + "/rest/apps?page=1&count=99").pipe(
      map(response => {
        return response["apps"];
      })
    );
  }

  getRoleApps(): Observable<SSOApp[]>{
    return this.http.get<any>(this.ssoGetRoleAppsSvc).pipe(
      map(response => {
        return response["appList"];
      })
    );
  }

  getAppIconURL(appId: string): string{
    return `${this.ssoAppIconSvc}/${appId}`;
  }

  getAccountDetails(): Observable<User> {
    return this.http.get<User>(this.ssoAccountDetailsSvc);
  }

  checkToken() {
    const storedToken: string = localStorage.getItem(this.storageKey);

    if (storedToken) {
      const user: SsoUser = this.retrieveUser();
      this.user.next(user);
      this.setKicker(user.exp);
    }
  }

  private setKicker(exp) {
    // exp datetime from token is in seconds from epoch, need to convert to millis from epoch first
    // add 60 seconds leeway 
    const delay = (Number(exp) * 1000) - new Date().getTime() - 60;
    console.log('Expiring in: ' + delay + 'ms')
    setTimeout(() => {
      this.expiredEvent.next(true);
    }, delay);
  }

  public retrieveUser(): SsoUser {
    return this.parseJwt(localStorage.getItem(this.storageKey).split(' ')[1]);
  }

  addReloadOnTokenDeletion() {
    window.onstorage = (e) => {
      if (e.key === this.storageKey) {
        if (e.newValue) {
          location.reload();
        }else{
          this.logout();
        }
      }
    };
  };

  private parseJwt(token: String): SsoUser {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  };

  logout() {
    this.user.next(null);
    localStorage.removeItem(this.storageKey);
    location.reload();
  }
}
