import { Component, OnInit, OnDestroy } from '@angular/core';
import { SsoUtilService } from 'src/app/services/sso-util/sso-util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit{

  constructor(private sso: SsoUtilService) { }

  accountDetailsSubscription: Subscription;

  username: string;
  fullname: string;
  roles: string;

  ngOnInit() {
    this.sso.getAccountDetails()
      .subscribe(user => {
        this.username = user.username;
        this.fullname = `${user.firstName} ${user.lastName}`;
        this.roles = "";
        user.roles.forEach(role => {
          this.roles = `${this.roles}|${role}`;
        })
        this.roles = this.roles.slice(1);
      },
      error => {
        this.sso.logout();
      });
  }

  logout(){
    this.sso.logout();
  }
}
