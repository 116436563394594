import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SetSecretComponent } from '../shared-components/set-secret/set-secret.component';
import { ChangePasswordComponent } from '../shared-components/change-password/change-password.component';
import { PopupService } from 'src/app/services/dialog/popup.service';
import { MatStepper } from '@angular/material/stepper';
import { AcctUtilService } from 'src/app/services/acct-util/acct-util.service';
import { GetUsernameComponent } from '../shared-components/get-username/get-username.component';
import { FieldConst } from 'src/app/constants/appConstants';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(GetUsernameComponent) private getUserName: GetUsernameComponent;
  @ViewChild(SetSecretComponent) private setSecret: SetSecretComponent;
  @ViewChild(ChangePasswordComponent) private changePassword: ChangePasswordComponent;
  setSecretTitle: string = "Answer Secret Questions";
  changePasswordTitle: string = "Change Password";
  doneMessg: string = "";
  username: string = "";
  question1: string = "";
  question2: string = "";
  reset: boolean = true;
  secretQnAs = {};

  constructor(public acct: AcctUtilService, public popupService: PopupService,
    private router: Router) { }

  ngOnInit() {
  }

  getUserQuestions() {
    this.username = this.getUserName.getValue();
    console.log(this.username)
    this.acct.getQuestions(this.username).subscribe(response => {
      this.secretQnAs = response;
      this.question1 = response[FieldConst.QUESTION1];
      this.question2 = response[FieldConst.QUESTION2];
      console.log(response)
      if (Object.entries(response).length !== 0 && response.constructor === Object) {
        this.stepper.selected.completed = true;
        this.stepper.next();
      }
    })
  }

  validateSecretAnswers() {
    console.log("validating answers...")
    let inputAnswers = this.setSecret.getValue();
    let data = {};
    data[FieldConst.ANSWER1] = inputAnswers[FieldConst.ANSWER1];
    data[FieldConst.ANSWER2] = inputAnswers[FieldConst.ANSWER2];
    data[FieldConst.USERNAME] = this.username;

    console.log(data)

    this.acct.validateSecret(data).subscribe(response => {
      console.log(response)
      if (response["action"] === "PROCEED") {
        this.stepper.selected.completed = true;
        this.stepper.next();
      } else {
        let popupText = {
          "message": "Invalid anwers",
          "btnText": 'Close'
        };
        this.popupService.showPopup(popupText);
      }
    })
  }

  resetPassword() {
    if (this.changePassword.isValidPassword()) {
      console.log("resetting password...")
      let data = {};
      data[FieldConst.USERNAME] = this.username;
      data[FieldConst.PASSWORD] = this.changePassword.getValue();
      console.log(data)

      this.acct.resetPassword(data).subscribe(response => {
        console.log(response)
        if (response["action"] === "PROCEED") {
          this.stepper.selected.completed = true;
          this.stepper.next();
          this.doneMessg = "Successfully Reset Password";
        } else {
          this.doneMessg = "Error while resetting password";
        }
      });
    }
  }

  goToPortal() {
    this.router.navigate(['/main']);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event) {
    $event.preventDefault();
    //remove token when leaving web app
    localStorage.removeItem(environment.storageKey);
  }
}
