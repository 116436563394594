import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FieldConst } from 'src/app/constants/appConstants';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {

  @Input("reset") reset: boolean = false;
  @Input("title") title: string = "";
  hide: boolean = true;
  formGroup: FormGroup;
  containsLowercase: boolean = false;
  containsUppercase: boolean = false;
  containsNumbers: boolean = false;
  containsSpecialChars: boolean = false;
  containsMinChars: boolean = false;
  minChars = 8;
  progressVal = 0;
  progressClass = "";

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      password: ['', Validators.required]
    });
  }

  getValue() {
    return this.formGroup.value[FieldConst.PASSWORD];
  }

  isValidPassword() {
    return (this.containsLowercase && this.containsUppercase && this.containsNumbers
      && this.containsSpecialChars && this.containsMinChars);
  }

  validateAllFormFields() {
    Object.keys(this.formGroup.controls).forEach(field => {
      const control = this.formGroup.get(field);
      control.markAsTouched();
      control.updateValueAndValidity();
    });

    this.checkPassCriteria();
  }

  checkPassCriteria() {
    this.progressVal = 0;

    let password = this.getValue();
    let lowercase = /[a-z]/;
    let uppercase = /[A-Z]/;
    let number = /[0-9]/;
    let specialChars = /\W+/;

    this.containsLowercase = lowercase.test(password);
    this.containsUppercase = uppercase.test(password);
    this.containsNumbers = number.test(password);
    this.containsSpecialChars = specialChars.test(password);
    this.containsMinChars = password.length >= this.minChars;

    this.progressVal = this.containsLowercase ? (this.progressVal + 20) : (this.progressVal);
    this.progressVal = this.containsUppercase ? (this.progressVal + 20) : (this.progressVal);
    this.progressVal = this.containsNumbers ? (this.progressVal + 20) : (this.progressVal);
    this.progressVal = this.containsSpecialChars ? (this.progressVal + 20) : (this.progressVal);
    this.progressVal = this.containsMinChars ? (this.progressVal + 20) : (this.progressVal);

    if (this.progressVal <= 40) {
      this.progressClass = "progress-red";
    } else if (this.progressVal <= 80) {
      this.progressClass = "progress-yellow";
    } else {
      this.progressClass = "progress-green";
    }
  }
}
