import { Injectable } from '@angular/core';


//Add property if config.json change
export interface ConfigModel{
  sample: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private jsonPath = "./assets/json/config.json";
  private data: ConfigModel;


  constructor() { 
  }

  public getData(): ConfigModel{
    return this.data;
  }

  public setData(data: ConfigModel){
    this.data = data;
  }

  public getConfigPath(): string{
    return this.jsonPath;
  }

}
