import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SsoUtilService } from 'src/app/services/sso-util/sso-util.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

  constructor(private sso: SsoUtilService) { }

  ngOnInit() {
  }

  onReturn() {
    // this.sso.logout(true);
  }

}
