import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { SetSecretComponent } from '../shared-components/set-secret/set-secret.component';
import { ChangePasswordComponent } from '../shared-components/change-password/change-password.component';
import { SsoUtilService } from 'src/app/services/sso-util/sso-util.service';
import { MatStepper } from '@angular/material/stepper';
import { PopupService } from 'src/app/services/dialog/popup.service';
import { AcctUtilService } from 'src/app/services/acct-util/acct-util.service';
import { FieldConst } from 'src/app/constants/appConstants';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SsoUser } from 'src/app/models/sso-user.model';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(SetSecretComponent) private setSecret: SetSecretComponent;
  @ViewChild(ChangePasswordComponent) private changePassword: ChangePasswordComponent;
  setSecretTitle: string = "Set Secret Questions";
  changePasswordTitle: string = "Set Password";
  doneMessg: string = "";
  username: string = "";
  question1: string = "Secret Question 1";
  question2: string = "Secret Question 2";
  answer1: string = "Secret Answer 1";
  answer2: string = "Secret Answer 2";
  ssoUser: SsoUser;
  tokenSubscription: Subscription;

  constructor(private sso: SsoUtilService, public acct: AcctUtilService,
    public popupService: PopupService, private router: Router) { }

  ngOnInit() {
    this.ssoUser = this.sso.retrieveUser();
    this.username = this.ssoUser.usr;
    console.log(this.username);
  }

  ngOnDestroy() { 
    // this.tokenSubscription.unsubscribe();
  }

  validateSecretAnswers() {
    console.log("validating answers...")
    let data = this.setSecret.getValue();
    console.log(data)

    if (this.setSecret.isValidForActivation()) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  sendValues() {
    if (this.changePassword.isValidPassword()) {
      console.log("activating acct...")
      let data = this.setSecret.getValue();
      data[FieldConst.USERNAME] = this.username;
      data[FieldConst.PASSWORD] = this.changePassword.getValue();
      console.log(data)

      this.acct.activateAccount(data).subscribe(response => {
        if (response["action"] === "PROCEED") {
          this.stepper.selected.completed = true;
          this.stepper.next();
          this.doneMessg = "Successfully Activated Account";
        } else {
          this.doneMessg = "Error while activating the account";
        }
      });
    }
  }

  goToPortal() {
    this.router.navigate(['/main']);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event) {
    $event.preventDefault();
    //remove token when leaving web app
    localStorage.removeItem(environment.storageKey);
  }
}
