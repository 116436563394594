import { NgModule, APP_INITIALIZER } from "@angular/core";
import { ConfigService, ConfigModel } from './services/config/config.service';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [{
        provide: APP_INITIALIZER,
        useFactory: (http: HttpClient, config: ConfigService) => {
            return () => {
                return new Promise((resolve, reject) =>{
                    http.get<ConfigModel>(config.getConfigPath())
                        .pipe(
                            map((data: ConfigModel) => {
                                config.setData(data);
                                resolve(true);
                            })
                        ).subscribe();
                });
            }
        },
        deps: [HttpClient, ConfigService],
        multi: true
    }]
})
export class InitializerModule {}