import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-get-username',
  templateUrl: './get-username.component.html',
  styleUrls: ['./get-username.component.css']
})
export class GetUsernameComponent implements OnInit {

  formGroup: FormGroup;
  
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      username: ['', Validators.required]
    });
  }

  getValue() {
    return this.formGroup.value["username"];
  }
}
